<template>
  <div class="order">
    <div class="order_top">
      <ul class="ul">
        <li
          v-for="item,index in menus"
          :key="index"
          :class="nowIndex==index?'color':''"
          @click="setTab(item,index)"
        >{{item.name}}</li>
        <li class="li_top">
          <span>粥公粥婆</span>
          <span>(黄河路店)</span>
          <span>(营业前测试)</span>
          <span title="重新加载" @click="againdata">
            <a-icon type="sync" />
          </span>
          <span title="请求远程" @click="datayuan">
            <a-icon type="phone" />
          </span>
          <span title="手写板" @click="dataxie">
            <a-icon type="form" />
          </span>
        </li>
      </ul>
    </div>
    <div class="order_main">
      <div class="main_left">
          <ul>
              <li  v-for="item,index in btnStr" :key="index" 
              :class="IndexCai==index?'CaiColor':''"
              @click="CaiBtn(item,index)"
              >
                  <p>
                    <span>{{index+1}}.</span>
                    <span>{{item.name}}</span>
                  </p>
                  <p>
                      <span>{{item.price}}</span>
                      <span>X{{item.num}}份 = </span>
                      <span>{{item.price*item.num}}</span>
                  </p>
              </li>
          </ul>
          <div class="main_b">
              <p>
                <span>{{btnNum}} </span>
                <span>/ {{btnStr.length}}</span>
              </p>
              <p>
                <span>未送: </span>
                <span>{{numPrice}}.00</span>
                <span>已送: </span>
                <span>{{0}}.00</span>
              </p>
          </div>
      </div>
      <div class="main_cent">
        <ul>
          <!-- <li v-for="item,index in btnList" :key="index" @click="btnBtn(index)">{{item}}</li> -->
          <li @click="btnXiang">明细</li>
          <li @click="btnJia">
            <a-icon type="plus" />
          </li>
          <li @click="btnJian">
            <a-icon type="minus" />
          </li>
          <li>修改数量</li>
          <li @click="btnDel">删除</li>
          <li>出菜方式</li>
          <li>做法</li>
          <li>口味</li>
          <li>配菜</li>
          <li>赠送</li>
          <li>临时品项</li>
        </ul>
      </div>
      <div class="main_data">
        <div class="main_data_top">
          <ul>
            <li v-for="item,index in menusData" :key="index" 
            :class="indexColor==index?'coloR':''"
            @click="menuBtn(item,index)" 
            >{{item}}</li>
          </ul>
        </div>
        <div class="main_data_max">
            <div v-if="0===number">
                <ul>
                    <li v-for="item,index in menuList" :key="index"
                    :class="indexPush==index?'colorPush':''"
                    @click="btnPush(item,index)"
                    >
                        <p>{{item.name}}</p>
                        <p>￥{{item.price}}</p>
                    </li>
                    <li>
                        上一页
                    </li>
                    <li>下一页</li>
                </ul>
            </div>
            <div v-if="1===number">
                <ul>
                    <li v-for="it,ind in menuListt" :key="ind">
                        <p>{{it.name}}</p>
                        <p>￥{{it.price}}</p>
                    </li>
                    <li>加菜</li>
                </ul>
            </div>
            <div v-if="2===number">
                <ul>
                    <li v-for="it,ind in menuList" :key="ind">
                        <p>{{it.name}}</p>
                        <p>￥{{it.price}}</p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="3===number">
                <ul>
                    <li v-for="it,ind in menuList" :key="ind">
                        <p>{{it.name}}</p>
                        <p>￥{{it.price}}</p>
                    </li>
                </ul>
            </div>
            <div v-if="4===number">222</div>
            <div v-if="5===number">222</div>
            <div v-if="6===number">222</div>
            <div v-if="7===number">222</div>
            <div v-if="8===number">222</div>
        </div>
      </div>
    </div>
    <div class="order_bottom">
      <p>整单</p>
      <p>单据</p>
      <p>先送</p>
      <p>补单不打印</p>
      <p>折扣</p>
      <p>获取品项</p>
      <p>结算</p>
      <p>
        <a-input-search class="search" placeholder="菜品编码" enter-button @search="onSearch" />
      </p>
      <p @click="okBtn">完成送单</p>
    </div>
    <!-- 详情模态框 -->
    <a-modal v-model="visible" title="Basic Modal" @ok="handleOk">
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </a-modal>
    <!-- 详情模态框 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible:false,
        ///显示隐藏
        number:0,
      menus: [
        {
          name: "主界面"
        },
        {
          name: "营业"
        }
      ],
      // btnList:[
      //     '明细','+','-','修改数量','删除','出菜方式','做法','口味','配菜','赠送','临时品项'
      // ],
      menusData: ['全部','十大特色','百年热菜','百年凉菜','百年好粥','百年烫羮','百年面点','酒水饮料','不能点','套餐'],
      menuList:[
          {
              name:'娃娃菜',
              price:25,
              num:1,
              id:1
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:14534
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:123423
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:14342
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:1231
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:111
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:122
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:133
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:112
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:11
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:14
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:13
          },
          {
              name:'娃娃菜',
              price:25,
               num:1,
               id:12
          }
      ],
      menuListt:[
          {
              name:'蚂蚁上树',
              price:256
          },
          {
              name:'冰川毛肚',
              price:365
          },
          {
              name:'爆炒牛蛙',
              price:298
          }
      ],
      nowIndex: 1,
      indexColor:0,
      ///cai
      IndexCai:1,
      ///点菜文字indx
      btnNum:0,
      ////点机菜数据
      btnStr:[],
      indexPush:0,
      idData:null,
      addList:[]
    };
  },
  created() {},
  computed: {
     ///未送总价
    numPrice: function() {
      var price = 0;
      for (var i = 0; i < this.btnStr.length; i++) {
        price += this.btnStr[i].price * this.btnStr[i].num;
      }
      return price;
    },
  },
  methods: {
    ///点菜按钮
    CaiBtn(item,index){
      this.IndexCai=index
      this.btnNum=index+1
    },
    ////中间按钮功能
    // btnBtn(e) {
    //   console.log("sss", e);
    // },
    ////内容按钮
    menuBtn(item,index){
        // console.log('99999999',e)
        this.number=index
        this.indexColor=index
        // console.log(this.indexColor)
    },
    setTab(item, index) {
      this.nowIndex = index;
      if (item.name == "主界面") {
        this.$router.push("/about");
      }
    },
    ///详情按钮
    btnXiang(){
      // console.log(this.pushList)
    },
    btnJia(){
      // console.log('jiajiajia',this.btnStr)
      console.log('jiajiajia',this.addList.num++)
    },
    btnJian(){
      this.btnStr.num--
    },
    btnDel(){
      console.log('deldeldel')
    },
    ///push点菜按钮
    btnPush(item,index){
      this.indexPush=index
      this.btnStr.push(item)
      console.log('a',item)
    },
    ////点菜下部搜索框
    onSearch(value) {
      console.log(value);
    },
    againdata() {
      this.$confirm({
        title: "提示",
        content: "确定要重新加载页面么?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          window.location.reload();
        },
        onCancel() {}
      });
    },
    datayuan() {
      this.$info({
        title: "提示",
        content: "功能暂未开放,敬请期待",
        okText: "确定",
        onOk() {}
      });
    },
    dataxie() {
      this.$info({
        title: "提示",
        content: "功能暂未开放,敬请期待",
        okText: "确定",
        onOk() {}
      });
    },
    handleOk(){

    },
    ///完成送单
    okBtn(){
      this.$router.push('/ying')
    }
  }
};
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
///菜颜色
.CaiColor{
  background: #FF6601;
  color: white;
}
.coloR{
    color: red;
}
.colorPush{
  border: 1px solid red;
}
.color {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.order {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #2e3e4e;
  .order_top {
    margin-top: 5px;
    .ul {
      display: flex;
      li {
        width: 150px;
        height: 30px;
        text-align: center;
        color: white;
        cursor: pointer;
      }
      .li_top {
        width: 20%;
        margin-left: 70%;
        span {
          color: orangered;
        }
        span:nth-of-type(4) {
          color: white;
          margin-left: 10px;
          margin-right: 10px;
        }
        span:nth-of-type(5) {
          color: white;
          margin-left: 10px;
          margin-right: 10px;
        }
        span:nth-of-type(6) {
          color: white;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
  //   中间内容
  .order_main {
    width: 100%;
    height: 90%;
    display: flex;
    margin-top: 5px;
    background: #B6B6B6;
    .main_left {
      width: 30%;
      background: #E5E5E4;
      ul{
        height: 95%;
        overflow: scroll;
        li{
                width: 100%;
                height: 80px;
                border-bottom: 1px solid white;
                line-height: 40px;
                cursor: pointer;
                p:nth-of-type(1){
                  span{
                    font-weight: bold;
                  }
                  span:nth-of-type(1){
                    margin-left: 10px;
                    margin-right: 10px;
                  }
                }
                p:nth-of-type(2){
                  span{
                    font-weight: bold;
                  }
                  span:nth-of-type(1){
                    margin-left: 70%;
                    margin-right: 10px;
                  }
                  span:nth-of-type(2){
                    margin-right: 20px;
                  }
                  span:nth-of-type(3){
                    color: orangered;
                  }
                }
        }
      }
      .main_b{
        height: 10%;
        display: flex;
        margin-top: 5px;
        line-height: 30px;
        p{
          font-size: 18px;
        }
        p:nth-of-type(1){
          margin-left: 10px;
          margin-right: 45%;
          span:nth-of-type(1){
            color: #FC1C00;
          }
        }
        p:nth-of-type(2){
          span:nth-of-type(2){
            margin-left: 10px;
            margin-right: 10px;
            color: #FC1C00 ;
          }
          span:nth-of-type(4){
            color: #FC1C00 ;
          }
        }
      }
    }
    .main_cent {
      width: 10%;
      background: #333333;
      ul {
        list-style: none;
        color: white;
        li {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          cursor: pointer;
          border-bottom: 1px solid white;
        }
        li:hover {
          background: #b6b6b6;
        }
      }
    }
    .main_data {
      width: 68%;
      background: #b6b6b6;
      .main_data_top{
          width: 100%;
          margin-top: 5px;
          margin-left: 5px;
          ul{
              display: flex;
              flex-wrap: wrap;
              color: white;
              li{
                  width: 15%;
                  height: 50px;
                  text-align: center;
                  line-height: 50px;
                  margin-bottom: 5px;
                  background: #727171;
                  margin-right: 5px;
                  font-weight: bold;
                  border-radius: 5px;
                  cursor: pointer;
              }
              li:nth-of-type(1){
                  background: orangered;
              }
              li:nth-of-type(1):hover{
                  background: orangered;
              }
              li:hover{
                  background: black;
              }
          }
      }
      .main_data_max{
          width: 100%;
          ul{
              display: flex;
              flex-wrap: wrap;
              li{
                  width: 15%;
                  height: 100px;
                  background: #E5E5E4;
                  cursor: pointer;
                  margin-left: 5px;
                  margin-bottom: 5px;
                  text-align: center;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: bold;
              }
              li:hover{
                  background: orangered;
                color: white;
              }
          }
      }
    }
  }
  //底部内容
  .order_bottom {
    width: 100%;
    height: 10%;
    display: flex;
    p{
      width: 100px;
      height: 40px;
      margin-top: 5px;
      border-radius: 5px;
      background: #E5E5E4;
      margin-right: 10px;
      text-align: center;
      line-height: 40px;
      font-weight: bold;
      cursor: pointer;
    }
    p:nth-of-type(1){
      margin-left: 10px;
    }
    p:nth-of-type(1):hover{
      background: #727171;
      color: white;
    }
    p:nth-of-type(2):hover{
      background: #727171;
      color: white;
    }
    p:nth-of-type(3):hover{
      background: #727171;
      color: white;
    }
    p:nth-of-type(4):hover{
      background: #727171;
      color: white;
    }
    p:nth-of-type(5):hover{
      background: #727171;
      color: white;
    }
    p:nth-of-type(6){
      margin-left: 40%;
    }
    p:nth-of-type(8){
      background: #2e3e4e;
      width: 150px;
    }
    p:nth-of-type(7){
      color: white;
      background: #EC661B;
    }
    p:nth-of-type(7):hover{
      background: #FDA97A;
    }
    p:nth-of-type(9){
      color: white;
      background: #EC661B;
    }
    p:nth-of-type(9):hover{
      background: #FDA97A;
    }
  }
}
</style>
